;(function($, window, document, undefined) {
    var AutoHeight = function(carousel) {
        this._core = carousel;
        this._handlers = {
            'initialized.owl.carousel': $.proxy(function() {
                if (this._core.settings.autoHeight) {
                    this.update();
                }
            }, this),
            'changed.owl.carousel': $.proxy(function(e) {
                if (this._core.settings.autoHeight && e.property.name == 'position') {
                    this.update();
                }
            }, this),
            'loaded.owl.lazy': $.proxy(function(e) {
                if (this._core.settings.autoHeight && e.element.closest('.' + this._core.settings.itemClass) === this._core.$stage.children().eq(this._core.current())) {
                    this.update();
                }
            }, this)
        };
        this._core.options = $.extend({}, AutoHeight.Defaults, this._core.options);
        this._core.$element.on(this._handlers);
    };
    AutoHeight.Defaults = {
        autoHeight: false,
        autoHeightClass: 'owl-height'
    };
    AutoHeight.prototype.update = function() {
        this._core.$stage.parent().height(this._core.$stage.children().eq(this._core.current()).height()).addClass(this._core.settings.autoHeightClass);
    };
    AutoHeight.prototype.destroy = function() {
        var handler, property;
        for (handler in this._handlers) {
            this._core.$element.off(handler, this._handlers[handler]);
        }
        for (property in Object.getOwnPropertyNames(this)) {
            typeof this[property] != 'function' && (this[property] = null);
        }
    };
    $.fn.owlCarousel.Constructor.Plugins.AutoHeight = AutoHeight;
})(window.Zepto || window.jQuery, window, document);;
(function($, window, document, undefined) {
    var Video = function(carousel) {
        this._core = carousel;
        this._videos = {};
        this._playing = null;
        this._fullscreen = false;
        this._handlers = {
            'resize.owl.carousel': $.proxy(function(e) {
                if (this._core.settings.video && !this.isInFullScreen()) {
                    e.preventDefault();
                }
            }, this),
            'refresh.owl.carousel changed.owl.carousel': $.proxy(function(e) {
                if (this._playing) {
                    this.stop();
                }
            }, this),
            'prepared.owl.carousel': $.proxy(function(e) {
                var $element = $(e.content).find('.owl-video');
                if ($element.length) {
                    $element.css('display', 'none');
                    this.fetch($element, $(e.content));
                }
            }, this)
        };
        this._core.options = $.extend({}, Video.Defaults, this._core.options);
        this._core.$element.on(this._handlers);
        this._core.$element.on('click.owl.video', '.owl-video-play-icon', $.proxy(function(e) {
            this.play(e);
        }, this));
    };
    Video.Defaults = {
        video: false,
        videoHeight: false,
        videoWidth: false
    };
    Video.prototype.fetch = function(target, item) {
        var type = target.attr('data-vimeo-id') ? 'vimeo' : 'youtube',
            id = target.attr('data-vimeo-id') || target.attr('data-youtube-id'),
            width = target.attr('data-width') || this._core.settings.videoWidth,
            height = target.attr('data-height') || this._core.settings.videoHeight,
            url = target.attr('href');
        if (url) {
            id = url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);
            if (id[3].indexOf('youtu') > -1) {
                type = 'youtube';
            } else if (id[3].indexOf('vimeo') > -1) {
                type = 'vimeo';
            } else {
                throw new Error('Video URL not supported.');
            }
            id = id[6];
        } else {
            throw new Error('Missing video URL.');
        }
        this._videos[url] = {
            type: type,
            id: id,
            width: width,
            height: height
        };
        item.attr('data-video', url);
        this.thumbnail(target, this._videos[url]);
    };
    Video.prototype.thumbnail = function(target, video) {
        var tnLink, icon, path, dimensions = video.width && video.height ? 'style="width:' + video.width + 'px;height:' + video.height + 'px;"' : '',
            customTn = target.find('img'),
            srcType = 'src',
            lazyClass = '',
            settings = this._core.settings,
            create = function(path) {
                icon = '<div class="owl-video-play-icon"></div>';
                if (settings.lazyLoad) {
                    tnLink = '<div class="owl-video-tn ' + lazyClass + '" ' + srcType + '="' + path + '"></div>';
                } else {
                    tnLink = '<div class="owl-video-tn" style="opacity:1;background-image:url(' + path + ')"></div>';
                }
                target.after(tnLink);
                target.after(icon);
            };
        target.wrap('<div class="owl-video-wrapper"' + dimensions + '></div>');
        if (this._core.settings.lazyLoad) {
            srcType = 'data-src';
            lazyClass = 'owl-lazy';
        }
        if (customTn.length) {
            create(customTn.attr(srcType));
            customTn.remove();
            return false;
        }
        if (video.type === 'youtube') {
            path = "http://img.youtube.com/vi/" + video.id + "/hqdefault.jpg";
            create(path);
        } else if (video.type === 'vimeo') {
            $.ajax({
                type: 'GET',
                url: 'http://vimeo.com/api/v2/video/' + video.id + '.json',
                jsonp: 'callback',
                dataType: 'jsonp',
                success: function(data) {
                    path = data[0].thumbnail_large;
                    create(path);
                }
            });
        }
    };
    Video.prototype.stop = function() {
        this._core.trigger('stop', null, 'video');
        this._playing.find('.owl-video-frame').remove();
        this._playing.removeClass('owl-video-playing');
        this._playing = null;
    };
    Video.prototype.play = function(ev) {
        this._core.trigger('play', null, 'video');
        if (this._playing) {
            this.stop();
        }
        var target = $(ev.target || ev.srcElement),
            item = target.closest('.' + this._core.settings.itemClass),
            video = this._videos[item.attr('data-video')],
            width = video.width || '100%',
            height = video.height || this._core.$stage.height(),
            html, wrap;
        if (video.type === 'youtube') {
            html = '<iframe width="' + width + '" height="' + height + '" src="http://www.youtube.com/embed/' + video.id + '?autoplay=1&v=' + video.id + '" frameborder="0" allowfullscreen></iframe>';
        } else if (video.type === 'vimeo') {
            html = '<iframe src="http://player.vimeo.com/video/' + video.id + '?autoplay=1" width="' + width + '" height="' + height + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
        }
        item.addClass('owl-video-playing');
        this._playing = item;
        wrap = $('<div style="height:' + height + 'px; width:' + width + 'px" class="owl-video-frame">' + html + '</div>');
        target.after(wrap);
    };
    Video.prototype.isInFullScreen = function() {
        var element = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        if (element && $(element).parent().hasClass('owl-video-frame')) {
            this._core.speed(0);
            this._fullscreen = true;
        }
        if (element && this._fullscreen && this._playing) {
            return false;
        }
        if (this._fullscreen) {
            this._fullscreen = false;
            return false;
        }
        if (this._playing) {
            if (this._core.state.orientation !== window.orientation) {
                this._core.state.orientation = window.orientation;
                return false;
            }
        }
        return true;
    };
    Video.prototype.destroy = function() {
        var handler, property;
        this._core.$element.off('click.owl.video');
        for (handler in this._handlers) {
            this._core.$element.off(handler, this._handlers[handler]);
        }
        for (property in Object.getOwnPropertyNames(this)) {
            typeof this[property] != 'function' && (this[property] = null);
        }
    };
    $.fn.owlCarousel.Constructor.Plugins.Video = Video;
})(window.Zepto || window.jQuery, window, document);;
(function($, window, document, undefined) {
    var Animate = function(scope) {
        this.core = scope;
        this.core.options = $.extend({}, Animate.Defaults, this.core.options);
        this.swapping = true;
        this.previous = undefined;
        this.next = undefined;
        this.handlers = {
            'change.owl.carousel': $.proxy(function(e) {
                if (e.property.name == 'position') {
                    this.previous = this.core.current();
                    this.next = e.property.value;
                }
            }, this),
            'drag.owl.carousel dragged.owl.carousel translated.owl.carousel': $.proxy(function(e) {
                this.swapping = e.type == 'translated';
            }, this),
            'translate.owl.carousel': $.proxy(function(e) {
                if (this.swapping && (this.core.options.animateOut || this.core.options.animateIn)) {
                    this.swap();
                }
            }, this)
        };
        this.core.$element.on(this.handlers);
    };
    Animate.Defaults = {
        animateOut: false,
        animateIn: false
    };
    Animate.prototype.swap = function() {
        if (this.core.settings.items !== 1 || !this.core.support3d) {
            return;
        }
        this.core.speed(0);
        var left, clear = $.proxy(this.clear, this),
            previous = this.core.$stage.children().eq(this.previous),
            next = this.core.$stage.children().eq(this.next),
            incoming = this.core.settings.animateIn,
            outgoing = this.core.settings.animateOut;
        if (this.core.current() === this.previous) {
            return;
        }
        if (outgoing) {
            left = this.core.coordinates(this.previous) - this.core.coordinates(this.next);
            previous.css({
                'left': left + 'px'
            }).addClass('animated owl-animated-out').addClass(outgoing).one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', clear);
        }
        if (incoming) {
            next.addClass('animated owl-animated-in').addClass(incoming).one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', clear);
        }
    };
    Animate.prototype.clear = function(e) {
        $(e.target).css({
            'left': ''
        }).removeClass('animated owl-animated-out owl-animated-in').removeClass(this.core.settings.animateIn).removeClass(this.core.settings.animateOut);
        this.core.transitionEnd();
    }
    Animate.prototype.destroy = function() {
        var handler, property;
        for (handler in this.handlers) {
            this.core.$element.off(handler, this.handlers[handler]);
        }
        for (property in Object.getOwnPropertyNames(this)) {
            typeof this[property] != 'function' && (this[property] = null);
        }
    };
    $.fn.owlCarousel.Constructor.Plugins.Animate = Animate;
})(window.Zepto || window.jQuery, window, document);;
(function($, window, document, undefined) {
    var Autoplay = function(scope) {
        this.core = scope;
        this.core.options = $.extend({}, Autoplay.Defaults, this.core.options);
        this.handlers = {
            'translated.owl.carousel refreshed.owl.carousel': $.proxy(function() {
                this.autoplay();
            }, this),
            'play.owl.autoplay': $.proxy(function(e, t, s) {
                this.play(t, s);
            }, this),
            'stop.owl.autoplay': $.proxy(function() {
                this.stop();
            }, this),
            'mouseover.owl.autoplay': $.proxy(function() {
                if (this.core.settings.autoplayHoverPause) {
                    this.pause();
                }
            }, this),
            'mouseleave.owl.autoplay': $.proxy(function() {
                if (this.core.settings.autoplayHoverPause) {
                    this.autoplay();
                }
            }, this)
        };
        this.core.$element.on(this.handlers);
    };
    Autoplay.Defaults = {
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: false,
        autoplaySpeed: false
    };
    Autoplay.prototype.autoplay = function() {
        if (this.core.settings.autoplay && !this.core.state.videoPlay) {
            window.clearInterval(this.interval);
            this.interval = window.setInterval($.proxy(function() {
                this.play();
            }, this), this.core.settings.autoplayTimeout);
        } else {
            window.clearInterval(this.interval);
        }
    };
    Autoplay.prototype.play = function(timeout, speed) {
        if (document.hidden === true) {
            return;
        }
        if (this.core.state.isTouch || this.core.state.isScrolling || this.core.state.isSwiping || this.core.state.inMotion) {
            return;
        }
        if (this.core.settings.autoplay === false) {
            window.clearInterval(this.interval);
            return;
        }
        this.core.next(this.core.settings.autoplaySpeed);
    };
    Autoplay.prototype.stop = function() {
        window.clearInterval(this.interval);
    };
    Autoplay.prototype.pause = function() {
        window.clearInterval(this.interval);
    };
    Autoplay.prototype.destroy = function() {
        var handler, property;
        window.clearInterval(this.interval);
        for (handler in this.handlers) {
            this.core.$element.off(handler, this.handlers[handler]);
        }
        for (property in Object.getOwnPropertyNames(this)) {
            typeof this[property] != 'function' && (this[property] = null);
        }
    };
    $.fn.owlCarousel.Constructor.Plugins.autoplay = Autoplay;
})(window.Zepto || window.jQuery, window, document);;
(function($, window, document, undefined) {
    'use strict';
    var Navigation = function(carousel) {
        this._core = carousel;
        this._initialized = false;
        this._pages = [];
        this._controls = {};
        this._templates = [];
        this.$element = this._core.$element;
        this._overrides = {
            next: this._core.next,
            prev: this._core.prev,
            to: this._core.to
        };
        this._handlers = {
            'prepared.owl.carousel': $.proxy(function(e) {
                if (this._core.settings.dotsData) {
                    this._templates.push($(e.content).find('[data-dot]').andSelf('[data-dot]').attr('data-dot'));
                }
            }, this),
            'add.owl.carousel': $.proxy(function(e) {
                if (this._core.settings.dotsData) {
                    this._templates.splice(e.position, 0, $(e.content).find('[data-dot]').andSelf('[data-dot]').attr('data-dot'));
                }
            }, this),
            'remove.owl.carousel prepared.owl.carousel': $.proxy(function(e) {
                if (this._core.settings.dotsData) {
                    this._templates.splice(e.position, 1);
                }
            }, this),
            'change.owl.carousel': $.proxy(function(e) {
                if (e.property.name == 'position') {
                    if (!this._core.state.revert && !this._core.settings.loop && this._core.settings.navRewind) {
                        var current = this._core.current(),
                            maximum = this._core.maximum(),
                            minimum = this._core.minimum();
                        e.data = e.property.value > maximum ? current >= maximum ? minimum : maximum : e.property.value < minimum ? maximum : e.property.value;
                    }
                }
            }, this),
            'changed.owl.carousel': $.proxy(function(e) {
                if (e.property.name == 'position') {
                    this.draw();
                }
            }, this),
            'refreshed.owl.carousel': $.proxy(function() {
                if (!this._initialized) {
                    this.initialize();
                    this._initialized = true;
                }
                this._core.trigger('refresh', null, 'navigation');
                this.update();
                this.draw();
                this._core.trigger('refreshed', null, 'navigation');
            }, this)
        };
        this._core.options = $.extend({}, Navigation.Defaults, this._core.options);
        this.$element.on(this._handlers);
    }
    Navigation.Defaults = {
        nav: false,
        navRewind: true,
        navText: ['prev', 'next'],
        navSpeed: false,
        navElement: 'div',
        navContainer: false,
        navContainerClass: 'owl-nav',
        navClass: ['owl-prev', 'owl-next'],
        slideBy: 1,
        dotClass: 'owl-dot',
        dotsClass: 'owl-dots',
        dots: true,
        dotsEach: false,
        dotData: false,
        dotsSpeed: false,
        dotsContainer: false,
        controlsClass: 'owl-controls'
    }
    Navigation.prototype.initialize = function() {
        var $container, override, options = this._core.settings;
        if (!options.dotsData) {
            this._templates = [$('<div>').addClass(options.dotClass).append($('<span>')).prop('outerHTML')];
        }
        if (!options.navContainer || !options.dotsContainer) {
            this._controls.$container = $('<div>').addClass(options.controlsClass).appendTo(this.$element);
        }
        this._controls.$indicators = options.dotsContainer ? $(options.dotsContainer) : $('<div>').hide().addClass(options.dotsClass).appendTo(this._controls.$container);
        this._controls.$indicators.on('click', 'div', $.proxy(function(e) {
            var index = $(e.target).parent().is(this._controls.$indicators) ? $(e.target).index() : $(e.target).parent().index();
            e.preventDefault();
            this.to(index, options.dotsSpeed);
        }, this));
        $container = options.navContainer ? $(options.navContainer) : $('<div>').addClass(options.navContainerClass).prependTo(this._controls.$container);
        this._controls.$next = $('<' + options.navElement + '>');
        this._controls.$previous = this._controls.$next.clone();
        this._controls.$previous.addClass(options.navClass[0]).html(options.navText[0]).hide().prependTo($container).on('click', $.proxy(function(e) {
            this.prev(options.navSpeed);
        }, this));
        this._controls.$next.addClass(options.navClass[1]).html(options.navText[1]).hide().appendTo($container).on('click', $.proxy(function(e) {
            this.next(options.navSpeed);
        }, this));
        for (override in this._overrides) {
            this._core[override] = $.proxy(this[override], this);
        }
    }
    Navigation.prototype.destroy = function() {
        var handler, control, property, override;
        for (handler in this._handlers) {
            this.$element.off(handler, this._handlers[handler]);
        }
        for (control in this._controls) {
            this._controls[control].remove();
        }
        for (override in this.overides) {
            this._core[override] = this._overrides[override];
        }
        for (property in Object.getOwnPropertyNames(this)) {
            typeof this[property] != 'function' && (this[property] = null);
        }
    }
    Navigation.prototype.update = function() {
        var i, j, k, options = this._core.settings,
            lower = this._core.clones().length / 2,
            upper = lower + this._core.items().length,
            size = options.center || options.autoWidth || options.dotData ? 1 : options.dotsEach || options.items;
        if (options.slideBy !== 'page') {
            options.slideBy = Math.min(options.slideBy, options.items);
        }
        if (options.dots || options.slideBy == 'page') {
            this._pages = [];
            for (i = lower, j = 0, k = 0; i < upper; i++) {
                if (j >= size || j === 0) {
                    this._pages.push({
                        start: i - lower,
                        end: i - lower + size - 1
                    });
                    j = 0, ++k;
                }
                j += this._core.mergers(this._core.relative(i));
            }
        }
    }
    Navigation.prototype.draw = function() {
        var difference, i, html = '',
            options = this._core.settings,
            $items = this._core.$stage.children(),
            index = this._core.relative(this._core.current());
        if (options.nav && !options.loop && !options.navRewind) {
            this._controls.$previous.toggleClass('disabled', index <= 0);
            this._controls.$next.toggleClass('disabled', index >= this._core.maximum());
        }
        this._controls.$previous.toggle(options.nav);
        this._controls.$next.toggle(options.nav);
        if (options.dots) {
            difference = this._pages.length - this._controls.$indicators.children().length;
            if (options.dotData && difference !== 0) {
                for (i = 0; i < this._controls.$indicators.children().length; i++) {
                    html += this._templates[this._core.relative(i)];
                }
                this._controls.$indicators.html(html);
            } else if (difference > 0) {
                html = new Array(difference + 1).join(this._templates[0]);
                this._controls.$indicators.append(html);
            } else if (difference < 0) {
                this._controls.$indicators.children().slice(difference).remove();
            }
            this._controls.$indicators.find('.active').removeClass('active');
            this._controls.$indicators.children().eq($.inArray(this.current(), this._pages)).addClass('active');
        }
        this._controls.$indicators.toggle(options.dots);
    }
    Navigation.prototype.onTrigger = function(event) {
        var settings = this._core.settings;
        event.page = {
            index: $.inArray(this.current(), this._pages),
            count: this._pages.length,
            size: settings && (settings.center || settings.autoWidth || settings.dotData ? 1 : settings.dotsEach || settings.items)
        };
    }
    Navigation.prototype.current = function() {
        var index = this._core.relative(this._core.current());
        return $.grep(this._pages, function(o) {
            return o.start <= index && o.end >= index;
        }).pop();
    }
    Navigation.prototype.getPosition = function(successor) {
        var position, length, options = this._core.settings;
        if (options.slideBy == 'page') {
            position = $.inArray(this.current(), this._pages);
            length = this._pages.length;
            successor ? ++position : --position;
            position = this._pages[((position % length) + length) % length].start;
        } else {
            position = this._core.relative(this._core.current());
            length = this._core.items().length;
            successor ? position += options.slideBy : position -= options.slideBy;
        }
        return position;
    }
    Navigation.prototype.next = function(speed) {
        $.proxy(this._overrides.to, this._core)(this.getPosition(true), speed);
    }
    Navigation.prototype.prev = function(speed) {
        $.proxy(this._overrides.to, this._core)(this.getPosition(false), speed);
    }
    Navigation.prototype.to = function(position, speed, standard) {
        var length;
        if (!standard) {
            length = this._pages.length;
            $.proxy(this._overrides.to, this._core)(this._pages[((position % length) + length) % length].start, speed);
        } else {
            $.proxy(this._overrides.to, this._core)(position, speed);
        }
    }
    $.fn.owlCarousel.Constructor.Plugins.Navigation = Navigation;
})(window.Zepto || window.jQuery, window, document);;
(function($, window, document, undefined) {
    'use strict';
    var Hash = function(carousel) {
        this._core = carousel;
        this._hashes = {};
        this.$element = this._core.$element;
        this._handlers = {
            'initialized.owl.carousel': $.proxy(function() {
                if (this._core.settings.startPosition == 'URLHash') {
                    $(window).trigger('hashchange.owl.navigation');
                }
            }, this),
            'prepared.owl.carousel': $.proxy(function(e) {
                var hash = $(e.content).find('[data-hash]').andSelf('[data-hash]').attr('data-hash');
                this._hashes[hash] = e.content;
            }, this)
        };
        this._core.options = $.extend({}, Hash.Defaults, this._core.options);
        this.$element.on(this._handlers);
        $(window).on('hashchange.owl.navigation', $.proxy(function() {
            var hash = window.location.hash.substring(1),
                items = this._core.$stage.children(),
                position = this._hashes[hash] && items.index(this._hashes[hash]) || 0;
            if (!hash) {
                return false;
            }
            this._core.to(position, false, true);
        }, this));
    }
    Hash.Defaults = {
        URLhashListener: false
    }
    Hash.prototype.destroy = function() {
        var handler, property;
        $(window).off('hashchange.owl.navigation');
        for (handler in this._handlers) {
            this._core.$element.off(handler, this._handlers[handler]);
        }
        for (property in Object.getOwnPropertyNames(this)) {
            typeof this[property] != 'function' && (this[property] = null);
        }
    }
    $.fn.owlCarousel.Constructor.Plugins.Hash = Hash;
})(window.Zepto || window.jQuery, window, document);;
(function($) {
    "use strict";
    $.fn.parallax = function() {
        var window_width = $(window).width();
        return this.each(function(i) {
            var $this = $(this);
            $this.addClass('parallax');

            function updateParallax(initial) {
                var container_height;
                if (window_width < 601) {
                    container_height = ($this.height() > 0) ? $this.height() : $this.children("img").height();
                } else {
                    container_height = ($this.height() > 0) ? $this.height() : 500;
                }
                var $img = $this.children("img").first();
                var img_height = $img.height();
                var parallax_dist = img_height - container_height + 100;
                var bottom = $this.offset().top + container_height;
                var top = $this.offset().top;
                var scrollTop = $(window).scrollTop();
                var windowHeight = window.innerHeight;
                var windowBottom = scrollTop + windowHeight;
                var percentScrolled = (windowBottom - top) / (container_height + windowHeight);
                var parallax = Math.round((parallax_dist * percentScrolled));
                if (initial) {
                    $img.css('display', 'block');
                }
                if ((bottom > scrollTop) && (top < (scrollTop + windowHeight))) {
                    $img.css('transform', "translate3D(-50%," + parallax + "px, 0)");
                }
            }
            $this.children("img").one("load", function() {
                updateParallax(true);
            }).each(function() {
                if (this.complete) $(this).load();
            });
            $(document).ready(function() {
                updateParallax(false);
            });
            $(window).scroll(function() {
                window_width = $(window).width();
                updateParallax(false);
            });
            $(window).resize(function() {
                window_width = $(window).width();
                updateParallax(false);
            });
        });
    };
    $(document).ready(function() {
        $('.stm-material-parallax').parallax();
        stm_widget_color_first_word();
        stm_widget_instagram();
        footerToBottom();
        stmFullwidthWithParallax();
        stmMobileMenu();
        stmShowListingIconFilter();
        disableFancyHandy();
        $.fn.is_on_screen = function() {
            var win = $(window);
            var viewport = {
                top: win.scrollTop(),
                left: win.scrollLeft()
            };
            viewport.right = viewport.left + win.width();
            viewport.bottom = viewport.top + win.height();
            var bounds = this.offset();
            bounds.right = bounds.left + this.outerWidth();
            bounds.bottom = bounds.top + this.outerHeight();
            return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
        };
        // $('.stm-customize-page .wpb_tabs').remove();
        // $( ".wpb_tabs" ).tabs();
        $("select").select2({
            width: '100%',
            minimumResultsForSearch: '1',
        })
        $('#tabs').tabs();
        // $('img.lazy').lazyload({
        //     effect: "fadeIn",
        //     failure_limit: Math.max('img'.length - 1, 0)
        // });
        $('[data-toggle="tooltip"]').tooltip()
        $(":checkbox,:radio").uniform({});
        $('.stm-date-timepicker').stm_datetimepicker({
            minDate: 0
        });
        $('.fancy-iframe').fancybox({
            type: 'iframe',
            padding: 0,
            maxWidth: '800px',
            width: '100%',
            fitToView: false,
            beforeLoad: function() {
                var url = $(this.element).data('url');
                this.href = url;
            }
        });
        $('.stm_fancybox').fancybox({
            fitToView: false,
            padding: 0,
            autoSize: true,
            closeClick: false,
            maxWidth: '100%',
            maxHeight: '90%',
            beforeShow: function() {
                $('body').addClass('stm_locked');
                this.title = $(this.element).attr("data-caption");
            },
            beforeClose: function() {
                $('body').removeClass('stm_locked');
            },
            helpers: {
                title: {
                    type: 'inside'
                },
                overlay: {
                    locked: false
                }
            }
        });
        $('#searchModal').on('shown.bs.modal', function(e) {
            $('#searchform .search-input').focus();
        });
        $('#searchDealerModal').on('shown.bs.modal', function(e) {
            $('#searchform .search-input').focus();
        });
        $('p').each(function() {
            if ($(this).html() == '') {
                $(this).addClass('stm-hidden');
            }
        })
        var pixelRatio = window.devicePixelRatio;
        if (typeof pixelRatio != 'undefined' && pixelRatio > 1) {
            $('img').each(function() {
                var stm_retina_image = $(this).data('retina');
                if (typeof stm_retina_image != 'undefined') {
                    $(this).attr('src', stm_retina_image);
                }
            })
        }
        // $('body').on('click', '.car-action-unit.add-to-compare.disabled', function(e) {
        //     e.preventDefault();
        //     e.stopPropagation();
        // })
        $('.quantity_actions span').on('click', function() {
            var quantityContainer = $(this).closest('.quantity'),
                quantityInput = quantityContainer.find('.qty'),
                quantityVal = quantityInput.attr('value');
            if ($(this).hasClass('plus')) {
                quantityInput.attr('value', parseInt(quantityVal) + 1);
            } else if ($(this).hasClass('minus')) {
                if (quantityVal > 1) {
                    quantityInput.attr('value', parseInt(quantityVal) - 1);
                }
            }
        });
        // $('.single-product .product-type-variable table.variations select').live("change", function() {
        //     $(this).parent().find('.select2-selection__rendered').text($(this).find('option[value="' + $(this).val() + '"]').text());
        // });
        $('body').on('click', '.stm-modern-filter-unit-images .stm-single-unit-image', function() {
            var stmHasChecked = false;
            $('.stm-modern-filter-unit-images .stm-single-unit-image .image').addClass('non-active');
            $('.stm-modern-filter-unit-images .stm-single-unit-image').each(function() {
                var checked = $(this).find('input[type=checkbox]').prop('checked');
                if (checked) {
                    $(this).find('.image').removeClass('non-active');
                    stmHasChecked = true;
                }
            });
            if (!stmHasChecked) {
                $('.stm-modern-filter-unit-images .stm-single-unit-image .image').removeClass('non-active');
            }
        })
        $('.stm-modern-view-others').click(function(e) {
            e.preventDefault();
            $(this).closest('.stm-single-unit-wrapper').find('.stm-modern-filter-others').slideToggle('fast');
        })
        $('.header-help-bar-trigger').click(function() {
            $(this).toggleClass('active');
            $('.header-help-bar ul').slideToggle();
        })
        $('.header-menu').click(function(e) {
            var link = $(this).attr('href');
            if (link == '#') {
                e.preventDefault();
            }
        })

        // $('.vc_tta-tab').click(function(){
        //     if(typeof map != 'undefined' && typeof center != 'undefined') {
        //         setTimeout(function () {
        //             google.maps.event.trigger(map, "resize");
        //             map.setCenter(center);
        //         }, 1000);
        //     }
        // })

        // $('.wpb_tour_tabs_wrapper.ui-tabs ul.wpb_tabs_nav > li').click(function(){
        //     console.log('click');
        //     if(typeof map != 'undefined' && typeof center != 'undefined') {
        //         setTimeout(function () {
        //             google.maps.event.trigger(map, "resize");
        //             map.setCenter(center);
        //         }, 1000);
        //     }
        // })

        $('#main .widget_search form.search-form input[type=search]').focus(function() {
            $(this).closest('form').addClass('focus');
        });
        $('#main .widget_search form.search-form input[type=search]').focusout(function() {
            $(this).closest('form').removeClass('focus');
        });
        $('body').on('change', '.stm-file-realfield', function() {
            var length = $(this)[0].files.length;
            if (length == 1) {
                var uploadVal = $(this).val();
                $(this).closest('.stm-pseudo-file-input').find(".stm-filename").text(uploadVal);
            } else if (length == 0) {
                $(this).closest('.stm-pseudo-file-input').find(".stm-filename").text('Choose file...');
            } else if (length > 1) {
                $(this).closest('.stm-pseudo-file-input').find(".stm-filename").text(length + ' files chosen');
            }
        });
        $('.sell-a-car-proceed').click(function(e) {
            e.preventDefault();
            var step = $(this).data(step);
            step = step.step;
            if (step == '2') {
                validateFirstStep();
                var errorsLength = Object.keys(errorFields.firstStep).length;
                if (errorsLength == 0) {
                    $('a[href="#step-one"]').removeClass('active');
                    $('a[href="#step-two"]').addClass('active');
                    $('.form-content-unit').slideUp();
                    $('#step-two').slideDown();
                }
            }
            if (step == '3') {
                $('a[href="#step-two"]').removeClass('active');
                $('a[href="#step-three"]').addClass('active');
                $('.form-content-unit').slideUp();
                $('#step-three').slideDown();
                $('a[href="#step-two"]').addClass('validated');
            }
        });
        $('.stm-sell-a-car-form input[type="submit"]').click(function(e) {
            validateFirstStep();
            validateThirdStep();
            $('a[href="#step-two"]').addClass('validated');
            var errorsLength = Object.keys(errorFields.firstStep).length;
            var errorsLength2 = Object.keys(errorFields.thirdStep).length;
            if (errorsLength != 0) {
                e.preventDefault();
                $('.form-navigation-unit').removeClass('active');
                $('a[href="#step-one"]').addClass('active');
                $('#step-three').slideUp();
                $('#step-one').slideDown();
            }
            if (errorsLength2 != 0) {
                e.preventDefault();
            } else {
                $('a[href="#step-three"]').addClass('validated');
            }
        })
    });
    $(window).load(function() {
        footerToBottom();
        stmFullwidthWithParallax();
        $('.stm-blackout-overlay').addClass('stm-blackout-loaded');
        stmPreloader();
    });
    $(window).resize(function() {
        footerToBottom();
        stmFullwidthWithParallax();
        disableFancyHandy();
    });

    function loadVisible($els, trigger) {
        $els.filter(function() {
            var rect = this.getBoundingClientRect();
            return rect.top >= 0 && rect.top <= window.innerHeight;
        }).trigger(trigger);
    }

    function footerToBottom() {
        var windowH = $(window).height();
        var footerH = $('#footer').outerHeight();
        $('#wrapper').css('min-height', (windowH - footerH) + 'px');
    };

    function stm_widget_color_first_word() {
        $('.stm_wp_widget_text .widget-title h6').each(function() {
            var html = $(this).html();
            var word = html.substr(0, html.indexOf(" "));
            var rest = html.substr(html.indexOf(" "));
            $(this).html(rest).prepend($("<span/>").html(word).addClass("colored"));
        });
    }

    function stm_widget_instagram() {
        $('#sb_instagram').closest('.widget-wrapper').addClass('stm-instagram-unit');
    }

    function stmFullwidthWithParallax() {
        var defaultWidth = $('.container').width();
        var screenWidth = $(window).width();
        var marginLeft = (screenWidth - defaultWidth) / 2;
        $('.stm-fullwidth-with-parallax').css({
            'width': screenWidth + 'px',
            'margin-left': '-' + marginLeft + 'px',
            'padding-left': marginLeft + 'px',
            'padding-right': marginLeft + 'px'
        })
    }

    function stmMobileMenu() {
        $('.mobile-menu-trigger').click(function() {
            $('.mobile-menu-holder').slideToggle();
        })
        $(".mobile-menu-holder .header-menu > li.menu-item-has-children > a").after('<span class="arrow"><i class="fa fa-angle-right"></i></span>');
        $('.mobile-menu-holder .header-menu .arrow').click(function() {
            $(this).toggleClass('active');
            $(this).closest('li').toggleClass('opened');
            $(this).closest('li').find('> ul.sub-menu').slideToggle(300);
        })
        $(".mobile-menu-holder .header-menu > li.menu-item-has-children > a").click(function(e) {
            if ($(this).attr('href') == '#') {
                e.preventDefault();
                $(this).closest('li').find(' > ul.sub-menu').slideToggle(300);
                $(this).closest('li').toggleClass('opened');
                $(this).closest('li').find('.arrow').toggleClass('active');
            }
        });
    }

    function disableFancyHandy() {
        var winWidth = $(window).width();
        if (winWidth < 1025) {
            $('.media-carousel-item .stm_fancybox').click(function(e) {
                e.preventDefault();
                e.stopPropagation();
            })
        }
    }

    function stmPreloader() {
        if ($('html').hasClass('stm-site-preloader')) {
            $('html').addClass('stm-site-loaded');
            setTimeout(function() {
                $('html').removeClass('stm-site-preloader stm-site-loaded');
            }, 250);
        }
    }

    function stmShowListingIconFilter() {
        $('.stm_icon_filter_label').click(function() {
            if (!$(this).hasClass('active')) {
                $(this).closest('.stm_icon_filter_unit').find('.stm_listing_icon_filter').toggleClass('active');
                $(this).closest('.stm_icon_filter_unit').find('.stm_listing_icon_filter .image').hide();
                $(this).addClass('active');
            } else {
                $(this).closest('.stm_icon_filter_unit').find('.stm_listing_icon_filter').toggleClass('active');
                $(this).closest('.stm_icon_filter_unit').find('.stm_listing_icon_filter .image').show();
                $(this).removeClass('active');
            }
        });
    }
})(jQuery);

// function stm_test_drive_car_title(id, title) {
//     var $ = jQuery;
//     $('.test-drive-car-name').text(title);
//     $('input[name=vehicle_id]').val(id);
// }

// function stm_isotope_sort_function(currentChoice) {
//     var $ = jQuery;
//     var stm_choice = currentChoice;
//     var $container = $('.stm-isotope-sorting');
//     console.log(stm_choice);
//     switch (stm_choice) {
//         case 'price_low':
//             $container.isotope({
//                 getSortData: {
//                     price: function(itemElem) {
//                         var price = $(itemElem).data('price');
//                         return parseFloat(price);
//                     }
//                 },
//                 sortBy: 'price',
//                 sortAscending: true
//             });
//             break
//         case 'price_high':
//             $container.isotope({
//                 getSortData: {
//                     price: function(itemElem) {
//                         var price = $(itemElem).data('price');
//                         return parseFloat(price);
//                     },
//                 },
//                 sortBy: 'price',
//                 sortAscending: false
//             });
//             break
//         case 'date_low':
//             $container.isotope({
//                 getSortData: {
//                     date: function(itemElem) {
//                         var date = $(itemElem).data('date');
//                         return parseFloat(date);
//                     },
//                 },
//                 sortBy: 'date',
//                 sortAscending: true
//             });
//             break
//         case 'date_high':
//             $container.isotope({
//                 getSortData: {
//                     date: function(itemElem) {
//                         var date = $(itemElem).data('date');
//                         return parseFloat(date);
//                     },
//                 },
//                 sortBy: 'date',
//                 sortAscending: false
//             });
//             break
//         case 'mileage_low':
//             $container.isotope({
//                 getSortData: {
//                     mileage: function(itemElem) {
//                         var mileage = $(itemElem).data('mileage');
//                         return parseFloat(mileage);
//                     }
//                 },
//                 sortBy: 'mileage',
//                 sortAscending: true
//             });
//             break
//         case 'mileage_high':
//             $container.isotope({
//                 getSortData: {
//                     mileage: function(itemElem) {
//                         var mileage = $(itemElem).data('mileage');
//                         return parseFloat(mileage);
//                     }
//                 },
//                 sortBy: 'mileage',
//                 sortAscending: false
//             });
//             break
//         default:
//             console.log('dont cheat');
//     }
//     $container.isotope('updateSortData').isotope();
// }
 var errorFields = {
     firstStep: {},
     secondStep: {},
     thirdStep: {},
 }

 function validateFirstStep() {
     errorFields.firstStep = {};
     var $ = jQuery;
     $('#step-one input[type="text"]').each(function() {
         var required = $(this).data('need');
         if (typeof required !== 'undefined') {
             if ($(this).attr('name') != 'video_url') {
                 if ($(this).val() == '') {
                     $(this).addClass('form-error');
                     errorFields.firstStep[$(this).attr('name')] = $(this).closest('.form-group').find('.contact-us-label').text();
                 } else {
                     $(this).removeClass('form-error');
                 }
             }
         }
     });
     var errorsLength = Object.keys(errorFields.firstStep).length;
     if (errorsLength == 0) {
         $('a[href="#step-one"]').addClass('validated');
     } else {
         $('a[href="#step-one"]').removeClass('validated');
     }
 }

 function validateThirdStep() {
     errorFields.thirdStep = {};
     var $ = jQuery;
     $('.contact-details input[type="text"],.contact-details input[type="email"]').each(function() {
         if ($(this).val() == '') {
             $(this).addClass('form-error');
             errorFields.thirdStep[$(this).attr('name')] = $(this).closest('.form-group').find('.contact-us-label').text();
         } else {
             $(this).removeClass('form-error');
         }
     })
 };
(function($) {
    "use strict";
    $(document).ready(function() {
        stm_default_header_height();
        stm_default_header();
        stm_fixed_transparent_header();
    });
    $(window).load(function() {
        stm_default_header_height();
        stm_default_header();
        stm_fixed_transparent_header();
    });
    $(window).resize(function() {
        stm_default_header_height();
        stm_default_header();
        stm_fixed_transparent_header();
    });
    $(window).scroll(function() {
        stm_default_header();
        stm_fixed_transparent_header();
    });

    function stm_default_header_height() {
        if ($('#header-nav-holder').length > 0 && $('.header-nav').length > 0 && $('.header-nav').hasClass('header-nav-default')) {
            var height = $('.header-nav').outerHeight();
            $('#header-nav-holder').css({
                'min-height': height + 'px'
            });
        }
    }

    function stm_default_header() {
        if ($('#header-nav-holder').length > 0 && $('.header-nav').length > 0 && $('.header-nav').hasClass('header-nav-default')) {
            var currentScrollPos = $(window).scrollTop();
            var headerPos = $('#header-nav-holder').offset().top;
            if (currentScrollPos >= headerPos) {
                $('.header-nav-default').addClass('header-nav-sticky');
            } else {
                $('.header-nav-default').removeClass('header-nav-sticky');
            }
        }
    }

    function stm_fixed_transparent_header() {
        if ($('#header-nav-holder').length > 0 && $('.header-nav').length > 0 && $('.header-nav').hasClass('header-nav-transparent')) {
            var currentScrollPos = $(window).scrollTop();
            var headerPos = $('#header-nav-holder').offset().top;
            if (currentScrollPos >= headerPos) {
                $('.header-nav-transparent').addClass('header-nav-sticky');
            } else {
                $('.header-nav-transparent').removeClass('header-nav-sticky');
            }
        }
    }
})(jQuery);


var $ = jQuery;
if ($('.header-service').length > 0) {
    var sections = [];
    var headerOffset = 48;
    var currentVisibleSection = '';
    var hasVisible = false;
    $(document).ready(function() {
        stm_sticky_service_header();
        $('.header-service .header-menu li a, .header-service .header-service-right .service-header-appointment, .stm-slider-button').click(function(e) {
            var name = $(this).attr('href');
            hash = name.split('#');
            var pageHasDiv = false;
            if (typeof hash[1] !== 'undefined') {
                if ($('#' + hash[1]).length) {
                    pageHasDiv = true;
                }
            }
            if (pageHasDiv) {
                if (typeof($('div#' + hash[1]).offset()) != 'undefined') {
                    e.preventDefault();
                    $('html, body').stop().animate({
                        scrollTop: $('div#' + hash[1]).offset().top - headerOffset
                    }, 700);
                }
            }
        })
        $('.service-mobile-menu-trigger').click(function() {
            $('.header-service .header-menu').slideToggle();
            $(this).toggleClass('active');
        });
    });
    $(window).load(function() {
        stm_sticky_service_header();
        getSections();
    });
    $(window).resize(function() {
        stm_sticky_service_header();
    });
    $(window).scroll(function() {
        stm_sticky_service_header();
        getSections();
    });

    function stm_sticky_service_header() {
        var currentScrollPos = $(window).scrollTop();
        var headerPos = $('#header').offset().top;
        if (currentScrollPos >= headerPos) {
            $('.header-service-fixed').addClass('header-service-sticky');
        } else {
            $('.header-service-fixed').removeClass('header-service-sticky');
        }
        if (sections) {
            hasVisible = false;
            sections.forEach(function(sectionObj) {
                if (currentScrollPos < sectionObj.height && (currentScrollPos + headerOffset + 1) > sectionObj.offset) {
                    currentVisibleSection = sectionObj.id;
                    $('.header-service .header-menu li').removeClass('active');
                    $('a[href="' + sectionObj.id + '"]').closest('li').addClass('active');
                    if (!hasVisible) {
                        hasVisible = true;
                    }
                }
            })
            if (!hasVisible) {
                $('.header-service .header-menu li').removeClass('active');
            }
        }
    }

    function getSections() {
        sections = [];
        $('.header-menu li').each(function() {
            var currentId = $(this).find('a').attr('href');
            if (currentId.charAt(0) == '#') {
                var currentIdOffset = $('div' + currentId).offset();
                if (typeof currentIdOffset != 'undefined') {
                    currentIdOffset = currentIdOffset.top;
                    var currenIdHeight = $('div' + currentId).outerHeight() + currentIdOffset;
                    sections.push({
                        id: currentId,
                        offset: currentIdOffset,
                        height: currenIdHeight
                    });
                }
            }
        });
    }
};
(function() {
    var defaultOptions = {
        frameRate: 150,
        animationTime: 400,
        stepSize: 100,
        pulseAlgorithm: true,
        pulseScale: 4,
        pulseNormalize: 1,
        accelerationDelta: 50,
        accelerationMax: 3,
        keyboardSupport: true,
        arrowScroll: 50,
        touchpadSupport: false,
        fixedBackground: true,
        excluded: ''
    };
    var options = defaultOptions;
    var isExcluded = false;
    var isFrame = false;
    var direction = {
        x: 0,
        y: 0
    };
    var initDone = false;
    var root = document.documentElement;
    var activeElement;
    var observer;
    var refreshSize;
    var deltaBuffer = [];
    var isMac = /^Mac/.test(navigator.platform);
    var key = {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        spacebar: 32,
        pageup: 33,
        pagedown: 34,
        end: 35,
        home: 36
    };

    function initTest() {
        if (options.keyboardSupport) {
            addEvent('keydown', keydown);
        }
    }

    function init() {
        if (initDone || !document.body) return;
        initDone = true;
        var body = document.body;
        var html = document.documentElement;
        var windowHeight = window.innerHeight;
        var scrollHeight = body.scrollHeight;
        root = (document.compatMode.indexOf('CSS') >= 0) ? html : body;
        activeElement = body;
        initTest();
        if (top != self) {
            isFrame = true;
        } else if (scrollHeight > windowHeight && (body.offsetHeight <= windowHeight || html.offsetHeight <= windowHeight)) {
            var fullPageElem = document.createElement('div');
            fullPageElem.style.cssText = 'position:absolute; z-index:-10000; ' + 'top:0; left:0; right:0; height:' + root.scrollHeight + 'px';
            document.body.appendChild(fullPageElem);
            var pendingRefresh;
            refreshSize = function() {
                if (pendingRefresh) return;
                pendingRefresh = setTimeout(function() {
                    if (isExcluded) return;
                    fullPageElem.style.height = '0';
                    fullPageElem.style.height = root.scrollHeight + 'px';
                    pendingRefresh = null;
                }, 500);
            };
            setTimeout(refreshSize, 10);
            addEvent('resize', refreshSize);
            var config = {
                attributes: true,
                childList: true,
                characterData: false
            };
            observer = new MutationObserver(refreshSize);
            observer.observe(body, config);
            if (root.offsetHeight <= windowHeight) {
                var clearfix = document.createElement('div');
                clearfix.style.clear = 'both';
                body.appendChild(clearfix);
            }
        }
        if (!options.fixedBackground && !isExcluded) {
            body.style.backgroundAttachment = 'scroll';
            html.style.backgroundAttachment = 'scroll';
        }
    }

    function cleanup() {
        observer && observer.disconnect();
        removeEvent(wheelEvent, wheel);
        removeEvent('mousedown', mousedown);
        removeEvent('keydown', keydown);
        removeEvent('resize', refreshSize);
        removeEvent('load', init);
    }
    var que = [];
    var pending = false;
    var lastScroll = Date.now();

    function scrollArray(elem, left, top) {
        directionCheck(left, top);
        if (options.accelerationMax != 1) {
            var now = Date.now();
            var elapsed = now - lastScroll;
            if (elapsed < options.accelerationDelta) {
                var factor = (1 + (50 / elapsed)) / 2;
                if (factor > 1) {
                    factor = Math.min(factor, options.accelerationMax);
                    left *= factor;
                    top *= factor;
                }
            }
            lastScroll = Date.now();
        }
        que.push({
            x: left,
            y: top,
            lastX: (left < 0) ? 0.99 : -0.99,
            lastY: (top < 0) ? 0.99 : -0.99,
            start: Date.now()
        });
        if (pending) {
            return;
        }
        var scrollWindow = (elem === document.body);
        var step = function(time) {
            var now = Date.now();
            var scrollX = 0;
            var scrollY = 0;
            for (var i = 0; i < que.length; i++) {
                var item = que[i];
                var elapsed = now - item.start;
                var finished = (elapsed >= options.animationTime);
                var position = (finished) ? 1 : elapsed / options.animationTime;
                if (options.pulseAlgorithm) {
                    position = pulse(position);
                }
                var x = (item.x * position - item.lastX) >> 0;
                var y = (item.y * position - item.lastY) >> 0;
                scrollX += x;
                scrollY += y;
                item.lastX += x;
                item.lastY += y;
                if (finished) {
                    que.splice(i, 1);
                    i--;
                }
            }
            if (scrollWindow) {
                window.scrollBy(scrollX, scrollY);
            } else {
                if (scrollX) elem.scrollLeft += scrollX;
                if (scrollY) elem.scrollTop += scrollY;
            }
            if (!left && !top) {
                que = [];
            }
            if (que.length) {
                requestFrame(step, elem, (1000 / options.frameRate + 1));
            } else {
                pending = false;
            }
        };
        requestFrame(step, elem, 0);
        pending = true;
    }

    function wheel(event) {
        if (!initDone) {
            init();
        }
        var target = event.target;
        var overflowing = overflowingAncestor(target);
        if (!overflowing || event.defaultPrevented || event.ctrlKey) {
            return true;
        }
        if (isNodeName(activeElement, 'embed') || (isNodeName(target, 'embed') && /\.pdf/i.test(target.src)) || isNodeName(activeElement, 'object')) {
            return true;
        }
        var deltaX = -event.wheelDeltaX || event.deltaX || 0;
        var deltaY = -event.wheelDeltaY || event.deltaY || 0;
        if (isMac) {
            if (event.wheelDeltaX && isDivisible(event.wheelDeltaX, 120)) {
                deltaX = -120 * (event.wheelDeltaX / Math.abs(event.wheelDeltaX));
            }
            if (event.wheelDeltaY && isDivisible(event.wheelDeltaY, 120)) {
                deltaY = -120 * (event.wheelDeltaY / Math.abs(event.wheelDeltaY));
            }
        }
        if (!deltaX && !deltaY) {
            deltaY = -event.wheelDelta || 0;
        }
        if (event.deltaMode === 1) {
            deltaX *= 40;
            deltaY *= 40;
        }
        if (!options.touchpadSupport && isTouchpad(deltaY)) {
            return true;
        }
        if (Math.abs(deltaX) > 1.2) {
            deltaX *= options.stepSize / 120;
        }
        if (Math.abs(deltaY) > 1.2) {
            deltaY *= options.stepSize / 120;
        }
        scrollArray(overflowing, deltaX, deltaY);
        event.preventDefault();
        scheduleClearCache();
    }

    function keydown(event) {
        var target = event.target;
        var modifier = event.ctrlKey || event.altKey || event.metaKey || (event.shiftKey && event.keyCode !== key.spacebar);
        if (!document.contains(activeElement)) {
            activeElement = document.activeElement;
        }
        var inputNodeNames = /^(textarea|select|embed|object)$/i;
        var buttonTypes = /^(button|submit|radio|checkbox|file|color|image)$/i;
        if (inputNodeNames.test(target.nodeName) || isNodeName(target, 'input') && !buttonTypes.test(target.type) || isNodeName(activeElement, 'video') || isInsideYoutubeVideo(event) || target.isContentEditable || event.defaultPrevented || modifier) {
            return true;
        }
        if ((isNodeName(target, 'button') || isNodeName(target, 'input') && buttonTypes.test(target.type)) && event.keyCode === key.spacebar) {
            return true;
        }
        var shift, x = 0,
            y = 0;
        var elem = overflowingAncestor(activeElement);
        var clientHeight = elem.clientHeight;
        if (elem == document.body) {
            clientHeight = window.innerHeight;
        }
        switch (event.keyCode) {
            case key.up:
                y = -options.arrowScroll;
                break;
            case key.down:
                y = options.arrowScroll;
                break;
            case key.spacebar:
                shift = event.shiftKey ? 1 : -1;
                y = -shift * clientHeight * 0.9;
                break;
            case key.pageup:
                y = -clientHeight * 0.9;
                break;
            case key.pagedown:
                y = clientHeight * 0.9;
                break;
            case key.home:
                y = -elem.scrollTop;
                break;
            case key.end:
                var damt = elem.scrollHeight - elem.scrollTop - clientHeight;
                y = (damt > 0) ? damt + 10 : 0;
                break;
            case key.left:
                x = -options.arrowScroll;
                break;
            case key.right:
                x = options.arrowScroll;
                break;
            default:
                return true;
        }
        scrollArray(elem, x, y);
        event.preventDefault();
        scheduleClearCache();
    }

    function mousedown(event) {
        activeElement = event.target;
    }
    var uniqueID = (function() {
        var i = 0;
        return function(el) {
            return el.uniqueID || (el.uniqueID = i++);
        };
    })();
    var cache = {};
    var clearCacheTimer;

    function scheduleClearCache() {
        clearTimeout(clearCacheTimer);
        clearCacheTimer = setInterval(function() {
            cache = {};
        }, 1 * 1000);
    }

    function setCache(elems, overflowing) {
        for (var i = elems.length; i--;) cache[uniqueID(elems[i])] = overflowing;
        return overflowing;
    }

    function overflowingAncestor(el) {
        var elems = [];
        var body = document.body;
        var rootScrollHeight = root.scrollHeight;
        do {
            var cached = cache[uniqueID(el)];
            if (cached) {
                return setCache(elems, cached);
            }
            elems.push(el);
            if (rootScrollHeight === el.scrollHeight) {
                var topOverflowsNotHidden = overflowNotHidden(root) && overflowNotHidden(body);
                var isOverflowCSS = topOverflowsNotHidden || overflowAutoOrScroll(root);
                if (isFrame && isContentOverflowing(root) || !isFrame && isOverflowCSS) {
                    return setCache(elems, getScrollRoot());
                }
            } else if (isContentOverflowing(el) && overflowAutoOrScroll(el)) {
                return setCache(elems, el);
            }
        } while (el = el.parentElement);
    }

    function isContentOverflowing(el) {
        return (el.clientHeight + 10 < el.scrollHeight);
    }

    function overflowNotHidden(el) {
        var overflow = getComputedStyle(el, '').getPropertyValue('overflow-y');
        return (overflow !== 'hidden');
    }

    function overflowAutoOrScroll(el) {
        var overflow = getComputedStyle(el, '').getPropertyValue('overflow-y');
        return (overflow === 'scroll' || overflow === 'auto');
    }

    function addEvent(type, fn) {
        window.addEventListener(type, fn, false);
    }

    function removeEvent(type, fn) {
        window.removeEventListener(type, fn, false);
    }

    function isNodeName(el, tag) {
        return (el.nodeName || '').toLowerCase() === tag.toLowerCase();
    }

    function directionCheck(x, y) {
        x = (x > 0) ? 1 : -1;
        y = (y > 0) ? 1 : -1;
        if (direction.x !== x || direction.y !== y) {
            direction.x = x;
            direction.y = y;
            que = [];
            lastScroll = 0;
        }
    }
    var deltaBufferTimer;
    if (window.localStorage && localStorage.SS_deltaBuffer) {
        deltaBuffer = localStorage.SS_deltaBuffer.split(',');
    }

    function isTouchpad(deltaY) {
        if (!deltaY) return;
        if (!deltaBuffer.length) {
            deltaBuffer = [deltaY, deltaY, deltaY];
        }
        deltaY = Math.abs(deltaY)
        deltaBuffer.push(deltaY);
        deltaBuffer.shift();
        clearTimeout(deltaBufferTimer);
        deltaBufferTimer = setTimeout(function() {
            if (window.localStorage) {
                localStorage.SS_deltaBuffer = deltaBuffer.join(',');
            }
        }, 1000);
        return !allDeltasDivisableBy(120) && !allDeltasDivisableBy(100);
    }

    function isDivisible(n, divisor) {
        return (Math.floor(n / divisor) == n / divisor);
    }

    function allDeltasDivisableBy(divisor) {
        return (isDivisible(deltaBuffer[0], divisor) && isDivisible(deltaBuffer[1], divisor) && isDivisible(deltaBuffer[2], divisor));
    }

    function isInsideYoutubeVideo(event) {
        var elem = event.target;
        var isControl = false;
        if (document.URL.indexOf('www.youtube.com/watch') != -1) {
            do {
                isControl = (elem.classList && elem.classList.contains('html5-video-controls'));
                if (isControl) break;
            } while (elem = elem.parentNode);
        }
        return isControl;
    }
    var requestFrame = (function() {
        return (window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function(callback, element, delay) {
            window.setTimeout(callback, delay || (1000 / 60));
        });
    })();
    var MutationObserver = (window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver);
    var getScrollRoot = (function() {
        var SCROLL_ROOT;
        return function() {
            if (!SCROLL_ROOT) {
                var dummy = document.createElement('div');
                dummy.style.cssText = 'height:10000px;width:1px;';
                document.body.appendChild(dummy);
                var bodyScrollTop = document.body.scrollTop;
                var docElScrollTop = document.documentElement.scrollTop;
                window.scrollBy(0, 1);
                if (document.body.scrollTop != bodyScrollTop)
                    (SCROLL_ROOT = document.body);
                else(SCROLL_ROOT = document.documentElement);
                window.scrollBy(0, -1);
                document.body.removeChild(dummy);
            }
            return SCROLL_ROOT;
        };
    })();

    function pulse_(x) {
        var val, start, expx;
        x = x * options.pulseScale;
        if (x < 1) {
            val = x - (1 - Math.exp(-x));
        } else {
            start = Math.exp(-1);
            x -= 1;
            expx = 1 - Math.exp(-x);
            val = start + (expx * (1 - start));
        }
        return val * options.pulseNormalize;
    }

    function pulse(x) {
        if (x >= 1) return 1;
        if (x <= 0) return 0;
        if (options.pulseNormalize == 1) {
            options.pulseNormalize /= pulse_(1);
        }
        return pulse_(x);
    }
    var userAgent = window.navigator.userAgent;
    var isEdge = /Edge/.test(userAgent);
    var isChrome = /chrome/i.test(userAgent) && !isEdge;
    var isSafari = /safari/i.test(userAgent) && !isEdge;
    var isMobile = /mobile/i.test(userAgent);
    var isEnabledForBrowser = (isChrome || isSafari) && !isMobile;
    var wheelEvent;
    if ('onwheel' in document.createElement('div')) wheelEvent = 'wheel';
    else if ('onmousewheel' in document.createElement('div')) wheelEvent = 'mousewheel';
    if (wheelEvent && isEnabledForBrowser) {
        addEvent(wheelEvent, wheel);
        addEvent('mousedown', mousedown);
        addEvent('load', init);
    }

    function SmoothScroll(optionsToSet) {
        for (var key in optionsToSet)
            if (defaultOptions.hasOwnProperty(key)) options[key] = optionsToSet[key];
    }
    SmoothScroll.destroy = cleanup;
    if (window.SmoothScrollOptions) SmoothScroll(window.SmoothScrollOptions)
    if (typeof define === 'function' && define.amd) define(function() {
        return SmoothScroll;
    });
    else if ('object' == typeof exports) module.exports = SmoothScroll;
    else window.SmoothScroll = SmoothScroll;
})();;
(function($) {
    "use strict";
    var stm_single_filter_link = false;
    $(document).ready(function() {

        $('.search_name').keyup(function(event) {
            delay(function(){
                var term = $('.search_name').val();;
                deleteMarkers();
                searchDealerships(map, term);
            }, 2000 );
        });



        $( ".stm-price-range" ).slider({
          range: true,
          step: 5000,
          min: 0,
          max: 1200000,
          values: [ 0, 1200000 ],
          slide: function( event, ui ) {
            $('#stm_filter_min_price').attr('value',ui.values[ 0 ]);
            $('#stm_filter_max_price').attr('value',ui.values[ 1 ]);
          },
          stop: function( event, ui ) {
            buildUrl();
          }
        });

        var delay = (function(){
            var timer = 0;
            return function(callback, ms){
                clearTimeout (timer);
                timer = setTimeout(callback, ms);
            };
        })();

        $('input[name="min_price"], input[name="max_price"]').keyup(function() {
            var value = $(this);
            delay(function(){
                if(value.val() != "") {
                    $( ".stm-price-range" ).slider('values',[$('#stm_filter_min_price').val(),$('#stm_filter_max_price').val()]);
                    buildUrl();
                }
            },1000);
        });
        //End Slider


        // stm_ajax_classic_filter();
        // stm_ajax_classic_filter_price();
        // stm_ajax_add_to_compare();
        // stm_ajax_remove_from_compare();
        // stm_ajax_filter_remove_hidden();
        // stm_ajax_add_test_drive();
        // stm_ajax_get_car_price();
        // clearFilter();
        // stm_filter_links();
        stm_sort_listing_cars();
        // stm_modern_filter_isotope();
        // $('body').on('click', '#modern-filter-listing .stm-clear-listing-one-unit', function() {
        //     var selectToClear = $(this).data('select');
        //     if (typeof selectToClear != 'undefined') {
        //         if (selectToClear != 'price' && $('select[name=' + selectToClear + ']').length > 0) {
        //             $('select[name=' + selectToClear + ']').select2('val', '');
        //         } else {
        //             if ($('.filter-price').length > 0) {
        //                 $('.stm-price-range').slider('values', [stmOptions.min, stmOptions.max]);
        //                 $("#stm_filter_min_price").val(stmOptions.min);
        //                 $("#stm_filter_max_price").val(stmOptions.max);
        //             }
        //         }
        //     }
        // });
        // $('body').on('click', '.stm-clear-listing-one-unit-classic', function() {
        //     var selectToClear = $(this).data('select');
        //     var clearLinkArg = $(this).data('url');
        //     if (typeof selectToClear != 'undefined') {
        //         if (selectToClear != 'price' && $('select[name=' + selectToClear + ']').length > 0) {
        //             window.location = clearLinkArg;
        //         } else {
        //             if ($('.filter-price').length > 0) {
        //                 $('.stm-price-range').slider('values', [stmOptions.min, stmOptions.max]);
        //                 $("#stm_filter_min_price").val(stmOptions.min);
        //                 $("#stm_filter_max_price").val(stmOptions.max);
        //                 $('.classic-filter-row form').submit();
        //             }
        //         }
        //     }
        // });
        // $('#stm-classic-filter-submit').click(function() {})
    });

    // function stm_ajax_classic_filter() {
    //     console.log("ajax filter");
    //     $('.classic-filter-row .filter-sidebar select').select2().on('change', function() {
    //         console.log("change");
    //         if (!stm_single_filter_link) {
    //             $('#stm-filter-links-input').val('');
    //         }
    //         // $.ajax({
    //         //     url: ajaxurl,
    //         //     dataType: 'json',
    //         //     context: this,
    //         //     data: $(this).closest('form').serialize() + '&action=stm_ajax_filter',
    //         //     beforeSend: function() {
    //         //         $('.stm-ajax-row').addClass('stm-loading');
    //         //         $('.classic-filter-row .filter-sidebar .select2-container--default .select2-selection--single .select2-selection__arrow b').addClass('stm-preloader');
    //         //     },
    //         //     success: function(data) {
    //         //         $('.classic-filter-row .filter-sidebar .select2-container--default .select2-selection--single .select2-selection__arrow b').removeClass('stm-preloader');
    //         //         $('.classic-filter-row .filter-sidebar select').prop("disabled", false);
    //         //         if (typeof data.binding != 'undefined') {
    //         //             for (var key in data.binding) {
    //         //                 if (data.binding.hasOwnProperty(key)) {
    //         //                     var obj = data.binding[key];
    //         //                     $('select[name=' + key + '] > option').each(function() {
    //         //                         var opt_val = $(this).val();
    //         //                         $(this).removeAttr('disabled');
    //         //                         if (opt_val != '') {
    //         //                             if (!obj.hasOwnProperty(opt_val)) {
    //         //                                 $(this).attr('disabled', '1');
    //         //                             }
    //         //                         }
    //         //                     })
    //         //                 }
    //         //             }
    //         //         }
    //         //         $('.classic-filter-row .filter-sidebar select').select2("destroy");
    //         //         $('.classic-filter-row .filter-sidebar select').select2();
    //         //         $('.filter.stm-vc-ajax-filter select').prop("disabled", false);
    //         //         if (typeof data.html != 'undefined') {
    //         //             $('.stm-ajax-row .stm-isotope-sorting').html(data.html);
    //         //             var sortVal = $('.stm-select-sorting select').select2('val');
    //         //             $('.stm-ajax-row').removeClass('stm-loading');
    //         //         }
    //         //         if (typeof data.pagination != 'undefined') {
    //         //             $('.stm-ajax-pagination').html(data.pagination);
    //         //         }
    //         //         if (typeof data.badges != 'undefined') {
    //         //             $('.stm-filter-chosen-units').html(data.badges);
    //         //         }
    //         //         // $("img.lazy").lazyload();
    //         //         // $container.isotope('reloadItems').isotope('layout');
    //         //         stm_isotope_sort_function(sortVal);
    //         //         window.history.pushState('', '', data.url);
    //         //     }
    //         // });
    //     });
    // }

    // function stm_ajax_classic_filter_price() {
    //     $(".classic-filter-row .stm-price-range").on('slidestop', function(event, ui) {
    //         $.ajax({
    //             url: ajaxurl,
    //             dataType: 'json',
    //             context: this,
    //             data: $(this).closest('form').serialize() + '&action=stm_ajax_filter',
    //             beforeSend: function() {
    //                 $('.stm-ajax-row').addClass('stm-loading');
    //             },
    //             success: function(data) {
    //                 $('.filter.stm-vc-ajax-filter select').prop("disabled", false);
    //                 if (typeof data.html != 'undefined') {
    //                     $('.stm-ajax-row .stm-isotope-sorting').html(data.html);
    //                     var sortVal = $('.stm-select-sorting select').select2('val');
    //                     $('.stm-ajax-row').removeClass('stm-loading');
    //                 }
    //                 if (typeof data.pagination != 'undefined') {
    //                     $('.stm-ajax-pagination').html(data.pagination);
    //                 }
    //                 if (typeof data.badges != 'undefined') {
    //                     $('.stm-filter-chosen-units').html(data.badges);
    //                 }
    //                 $("img.lazy").lazyload();
    //                 // $container.isotope('reloadItems').isotope('layout');
    //                 stm_isotope_sort_function(sortVal);
    //                 window.history.pushState('', '', data.url);
    //             }
    //         });
    //     });
    // }

    // function stm_ajax_filter_remove_hidden() {
    //     $('.filter.stm-vc-ajax-filter select').select2().on('change', function() {
    //         $.ajax({
    //             url: ajaxurl,
    //             dataType: 'json',
    //             context: this,
    //             data: $(this).closest('form').serialize() + '&action=stm_ajax_filter_remove_hidden',
    //             beforeSend: function() {
    //                 $('.filter.stm-vc-ajax-filter select').prop("disabled", true);
    //                 $('.select2-container--default .select2-selection--single .select2-selection__arrow b').addClass('stm-preloader');
    //             },
    //             success: function(data) {
    //                 $('.select2-container--default .select2-selection--single .select2-selection__arrow b').removeClass('stm-preloader');
    //                 $('.filter.stm-vc-ajax-filter select').prop("disabled", false);
    //                 if (typeof data.binding != 'undefined') {
    //                     for (var key in data.binding) {
    //                         if (data.binding.hasOwnProperty(key)) {
    //                             var obj = data.binding[key];
    //                             $('select[name=' + key + '] > option').each(function() {
    //                                 var opt_val = $(this).val();
    //                                 $(this).removeAttr('disabled');
    //                                 if (opt_val != '') {
    //                                     if (!obj.hasOwnProperty(opt_val)) {
    //                                         $(this).attr('disabled', '1');
    //                                     }
    //                                 }
    //                             })
    //                         }
    //                     }
    //                 }
    //                 $('.filter.stm-vc-ajax-filter select').select2("destroy");
    //                 $('.filter.stm-vc-ajax-filter select').select2();
    //             }
    //         });
    //         return false;
    //     });
    //     var tab_id = '';
    //     $('.filter-listing.stm-vc-ajax-filter select').on('select2:open', function(e) {
    //         var selectClass = e.currentTarget.dataset.class;
    //         if (typeof(selectClass) != 'undefined') {
    //             $('.select2-container--open').addClass(selectClass);
    //         }
    //     });


    //     // $('.filter-listing.stm-vc-ajax-filter select').select2().on('change', function() {
    //     //     $.ajax({
    //     //         url: ajaxurl,
    //     //         dataType: 'json',
    //     //         context: this,
    //     //         data: $(this).closest('form').serialize() + '&action=stm_ajax_filter_remove_hidden',
    //     //         beforeSend: function() {
    //     //             tab_id = $(this).closest('.tab-pane').attr('id');
    //     //             tab_id = '.filter-listing.stm-vc-ajax-filter #' + tab_id + ' .stm-ajax-reloadable';
    //     //             $(tab_id + ' select').prop("disabled", true);
    //     //             $(tab_id + ' .select2-container--default b').addClass('stm-preloader');
    //     //         },
    //     //         success: function(data) {
    //     //             console.log(data);
    //     //             if (typeof data.length != 'undefined') {
    //     //                 $(this).closest('.tab-pane').find('button[type="submit"] span').text(data.length);
    //     //             }
    //     //             $(tab_id + ' .select2-container--default b').removeClass('stm-preloader');
    //     //             $(tab_id + ' select').prop("disabled", false);
    //     //             if (typeof data.binding != 'undefined') {
    //     //                 for (var key in data.binding) {
    //     //                     if (data.binding.hasOwnProperty(key)) {
    //     //                         var obj = data.binding[key];
    //     //                         $(tab_id + ' select[name=' + key + '] > option').each(function() {
    //     //                             var opt_val = $(this).val();
    //     //                             $(this).removeAttr('disabled');
    //     //                             if (opt_val != '') {
    //     //                                 if (!obj.hasOwnProperty(opt_val)) {
    //     //                                     $(this).attr('disabled', '1');
    //     //                                 }
    //     //                             }
    //     //                         })
    //     //                     }
    //     //                 }
    //     //             }
    //     //             $(tab_id + ' select').select2("destroy");
    //     //             $(tab_id + ' select').select2();
    //     //         }
    //     //     });
    //     //     return false;
    //     // });
    // };

    // function stm_ajax_add_to_compare() {
    //     $(document).on('click', '.add-to-compare', function(e) {
    //         e.preventDefault();
    //         var dataId = $(this).attr('data-id');
    //         var dataAction = $(this).attr('data-action');
    //         if (typeof dataAction == 'undefined') {
    //             dataAction = 'add';
    //         }
    //         var stm_timeout;
    //         if (typeof dataId != 'undefined') {
    //             $.ajax({
    //                 url: ajaxurl,
    //                 type: "POST",
    //                 dataType: 'json',
    //                 data: '&post_id=' + dataId + '&post_action=' + dataAction + '&action=stm_ajax_add_to_compare',
    //                 context: this,
    //                 beforeSend: function(data) {
    //                     $(this).addClass('disabled');
    //                     clearTimeout(stm_timeout);
    //                 },
    //                 success: function(data) {
    //                     $(this).removeClass('disabled');
    //                     clearTimeout(stm_timeout);
    //                     $('.single-add-to-compare').addClass('single-add-to-compare-visible');
    //                     if (typeof data.response != 'undefined') {
    //                         $('.single-add-to-compare .stm-title').text(data.response);
    //                     }
    //                     if (typeof data.length != 'undefined') {
    //                         $('.stm-current-cars-in-compare').text(data.length);
    //                     }
    //                     stm_timeout = setTimeout(function() {
    //                         $('.single-add-to-compare').removeClass('single-add-to-compare-visible');
    //                     }, 5000);
    //                     if (data.status != 'danger') {
    //                         if (dataAction == 'remove') {
    //                             $(this).removeClass('stm-added');
    //                             $(this).html('<i class="stm-icon-add"></i>' + data.add_to_text);
    //                             $(this).removeClass('hovered');
    //                             $(this).attr('data-action', 'add');
    //                         } else {
    //                             $(this).addClass('stm-added');
    //                             $(this).html('<i class="stm-icon-added stm-unhover"></i><span class="stm-unhover">' + data.in_com_text + '</span><div class="stm-show-on-hover"><i class="stm-icon-remove"></i>' + data.remove_text + '</div>');
    //                             $(this).removeClass('hovered');
    //                             $(this).attr('data-action', 'remove');
    //                         }
    //                     }
    //                 }
    //             });
    //         }
    //     });
    // }

    // function stm_ajax_remove_from_compare() {
    //     $(document).on('click', '.remove-from-compare', function(e) {
    //         e.preventDefault();
    //         var dataId = $(this).attr('data-id');
    //         var dataAction = $(this).attr('data-action');
    //         if (typeof dataId != 'undefined') {
    //             $.ajax({
    //                 url: ajaxurl,
    //                 type: "POST",
    //                 dataType: 'json',
    //                 data: '&post_id=' + dataId + '&post_action=' + dataAction + '&action=stm_ajax_add_to_compare',
    //                 context: this,
    //                 beforeSend: function(data) {
    //                     $(this).addClass('loading');
    //                     if (parseFloat($('.stm-current-cars-in-compare').text()) > 0) {
    //                         $('.stm-current-cars-in-compare').text(parseFloat($('.stm-current-cars-in-compare').text()) - 1);
    //                     }
    //                     $('.car-listing-row .compare-col-stm-' + dataId).hide('slide', {
    //                         direction: 'left'
    //                     }, function() {
    //                         $('.car-listing-row .compare-col-stm-' + dataId).remove();
    //                         $('.car-listing-row').append($('.compare-empty-car-top').html());
    //                     });
    //                     $('.stm-compare-row .compare-col-stm-' + dataId).hide('slide', {
    //                         direction: 'left'
    //                     }, function() {
    //                         $('.stm-compare-row .compare-col-stm-' + dataId).remove();
    //                         $('.stm-compare-row').append($('.compare-empty-car-bottom').html());
    //                     });
    //                     $('.row-compare-features .compare-col-stm-' + dataId).hide('slide', {
    //                         direction: 'left'
    //                     }, function() {
    //                         $('.row-compare-features .compare-col-stm-' + dataId).remove();
    //                         if ($('.row-compare-features .col-md-3').length < 2) {
    //                             $('.row-compare-features').slideUp();
    //                         }
    //                     });
    //                 },
    //             });
    //         }
    //     });
    // }

    // function stm_ajax_add_test_drive() {
    //     $('#test-drive form').on("submit", function(event) {
    //         event.preventDefault();
    //         $.ajax({
    //             url: ajaxurl,
    //             type: "POST",
    //             dataType: 'json',
    //             context: this,
    //             data: $(this).serialize() + '&action=stm_ajax_add_test_drive',
    //             beforeSend: function() {
    //                 $('.alert-modal').remove();
    //                 $(this).closest('form').find('input').removeClass('form-error');
    //                 $(this).closest('form').find('.stm-ajax-loader').addClass('loading');
    //             },
    //             success: function(data) {
    //                 $(this).closest('form').find('.stm-ajax-loader').removeClass('loading');
    //                 $(this).closest('form').find('.modal-body').append('<div class="alert-modal alert alert-' + data.status + ' text-left">' + data.response + '</div>')
    //                 for (var key in data.errors) {
    //                     $('#request-test-drive-form input[name="' + key + '"]').addClass('form-error');
    //                 }
    //             }
    //         });
    //         $(this).closest('form').find('.form-error').live('hover', function() {
    //             $(this).removeClass('form-error');
    //         });
    //     });
    // }

    // function stm_ajax_get_car_price() {
    //     $('#get-car-price form').on("submit", function(event) {
    //         event.preventDefault();
    //         $.ajax({
    //             url: ajaxurl,
    //             type: "POST",
    //             dataType: 'json',
    //             context: this,
    //             data: $(this).serialize() + '&action=stm_ajax_get_car_price',
    //             beforeSend: function() {
    //                 $('.alert-modal').remove();
    //                 $(this).closest('form').find('input').removeClass('form-error');
    //                 $(this).closest('form').find('.stm-ajax-loader').addClass('loading');
    //             },
    //             success: function(data) {
    //                 $(this).closest('form').find('.stm-ajax-loader').removeClass('loading');
    //                 $(this).closest('form').find('.modal-body').append('<div class="alert-modal alert alert-' + data.status + ' text-left">' + data.response + '</div>')
    //                 for (var key in data.errors) {
    //                     $('#get-car-price input[name="' + key + '"]').addClass('form-error');
    //                 }
    //             }
    //         });
    //         $(this).closest('form').find('.form-error').live('hover', function() {
    //             $(this).removeClass('form-error');
    //         });
    //     });
    // }

    // function clearFilter() {
    //     $('.reset-all').click(function(e) {
    //         e.preventDefault();
    //         $(this).closest('.filter').find('select').each(function() {
    //             $(this).select2('val', '');
    //         })
    //         if ($('.filter-price').length > 0) {
    //             $('.stm-price-range').slider('values', [stmOptions.min, stmOptions.max]);
    //             $("#stm_filter_min_price").val(stmOptions.min);
    //             $("#stm_filter_max_price").val(stmOptions.max);
    //         }
    //     });
    // }
    var stmIsotope;
    var $container = $('.stm-isotope-sorting');

    function stm_sort_listing_cars() {
        $('.stm-select-sorting select').select2().on('change', function() {
            var sort_val = $(this).select2('val');

            $('input[name="sort_order"]').val(sort_val);

            buildUrl();
        });
        $('#condition, #max_mileage, #make, #model, #year, #body, #dealer_code, #d_name').select2().on('change', function() {
            buildUrl();
        });
        $('.view-type').on('click',function(item) {

            $('input[name="view_type"]').val($(this).data('view'));
            $('.view-type').removeClass('active');
            $(this).addClass('active');

            buildUrl();
        });
    }

    // $('.stm-select-sorting select').val('val', 'demo');

    // function stm_modern_filter_isotope() {
    //     if ($('#modern-filter-listing').length > 0) {
    //         $('body').on('click', '.modern-filter-badges ul li i', function() {
    //             var tab_reset = $(this).data('select');
    //             if (tab_reset == 'price') {
    //                 $('.stm-price-range').slider('values', [stmOptions.min, stmOptions.max]);
    //                 $("#stm_filter_min_price").val(stmOptions.min);
    //                 $("#stm_filter_max_price").val(stmOptions.max);
    //                 stmIso.isotope({
    //                     filter: function() {
    //                         var itemPrice = $(this).data('price');
    //                         return parseInt(itemPrice, 10) >= stmOptions.min && parseInt(itemPrice, 10) <= stmOptions.max;
    //                     }
    //                 })
    //                 price_string = '<li><span>Price:</span> ' + stmOptions.min + ' - ' + stmOptions.max;
    //                 price_string += '<i class="fa fa-close stm-clear-listing-one-unit" data-select="price"></i></li>';
    //                 $(this).closest('li').remove();
    //             } else {
    //                 $('#' + tab_reset + ' input[type=checkbox]').each(function() {
    //                     if ($(this).prop('checked')) {
    //                         $(this).trigger('click');
    //                     }
    //                 })
    //             }
    //             if (typeof stmIsotope != 'undefined') {
    //                 var stmIsoData = stmIsotope.data('isotope');
    //                 if (typeof stmIsoData != 'undefined') {
    //                     if (typeof stmIsoData.filteredItems != 'undefined') {
    //                         $('.stm-modern-filter-found-cars span').text(stmIsoData.filteredItems.length);
    //                     }
    //                 }
    //             }
    //         })
    //         var stmSortClasses = '';
    //         var main_string = '';
    //         var price_string = '';
    //         var string = '';
    //         var stmIso = $('.stm-isotope-sorting');
    //         var stmFilterGroups = {};
    //         $('#modern-filter-listing input[type=checkbox]').click(function() {
    //             var badges = {};
    //             var badges_reset = {};
    //             stmFilterGroups = {};
    //             $(window).scroll();
    //             var stmFirst = 0;
    //             stmSortClasses = '';
    //             var numberOfCats = 0;
    //             $('#modern-filter-listing input[type=checkbox]').each(function() {
    //                 var stmChecked = $(this).prop('checked');
    //                 var stmCurrentClass = $(this).attr('name');
    //                 var stmBadgeValue = $(this).data('name');
    //                 var stmBadgeId = $(this).closest('.content').attr('id');
    //                 var stmFilterCurrentGroup = $(this).closest('.collapse').attr('id');
    //                 if (stmChecked) {
    //                     var tab = $(this).closest('.stm-accordion-single-unit').find('.title h5').text();
    //                     if (typeof badges[tab] == 'undefined') {
    //                         badges[tab] = [];
    //                     }
    //                     if (typeof badges_reset[tab] == 'undefined') {
    //                         badges_reset[tab] = '';
    //                     }
    //                     badges[tab].push(stmBadgeValue);
    //                     badges_reset[tab] = stmBadgeId;
    //                     if (stmFirst == 0) {
    //                         stmSortClasses += '.' + stmCurrentClass;
    //                     } else {
    //                         stmSortClasses += '.' + stmCurrentClass;
    //                     }
    //                     stmFirst++;
    //                     if (typeof(stmFilterGroups[stmFilterCurrentGroup]) == 'undefined') {
    //                         stmFilterGroups[stmFilterCurrentGroup] = [];
    //                     }
    //                     stmFilterGroups[stmFilterCurrentGroup].push(stmCurrentClass);
    //                 }
    //                 if (stmSortClasses == '') {
    //                     stmSortClasses = '.all';
    //                 }
    //             })
    //             if ($('.stm-isotope-sorting').length > 0 && stmSortClasses != '') {
    //                 var matches = [];
    //                 stmIso.isotope({
    //                     filter: function() {
    //                         matches = [];
    //                         var itemPrice = $(this).data('price');
    //                         var minPrice = $('#stm_filter_min_price').val();
    //                         var maxPrice = $('#stm_filter_max_price').val();
    //                         if (Object.keys(stmFilterGroups).length > 0) {
    //                             for (var key in stmFilterGroups) {
    //                                 if (stmFilterGroups.hasOwnProperty(key)) {
    //                                     for (var k = 0; k < stmFilterGroups[key].length; k++) {
    //                                         var match = false;
    //                                         if ($(this).hasClass(stmFilterGroups[key][k])) {
    //                                             matches[key] = true;
    //                                         }
    //                                     }
    //                                 }
    //                             }
    //                             var final_match = false;
    //                             if (Object.keys(matches).length == Object.keys(stmFilterGroups).length) {
    //                                 if (Object.keys(matches).length > 0) {
    //                                     for (var m_key in matches) {
    //                                         if (matches.hasOwnProperty(m_key)) {
    //                                             if (matches[m_key]) {
    //                                                 final_match = true;
    //                                             } else {
    //                                                 final_match = false;
    //                                             }
    //                                         }
    //                                     }
    //                                 } else {
    //                                     final_match = false;
    //                                 }
    //                             }
    //                             if (final_match) {
    //                                 if (typeof minPrice != 'undefined' && typeof maxPrice != 'undefined' && typeof itemPrice != 'undefined') {
    //                                     return parseInt(itemPrice, 10) >= minPrice && parseInt(itemPrice, 10) <= maxPrice;
    //                                 } else {
    //                                     return ($(this));
    //                                 }
    //                             }
    //                         } else {
    //                             return ($(this));
    //                         }
    //                     }
    //                 })
    //             }
    //             string = '';
    //             for (var key in badges) {
    //                 if (badges.hasOwnProperty(key)) {
    //                     if (badges.hasOwnProperty(key)) {
    //                         string += '<li><span>' + key + ':</span> ' + badges[key].join(', ');
    //                         if (badges_reset.hasOwnProperty(key)) {
    //                             string += '<i class="fa fa-close stm-clear-listing-one-unit" data-select="' + badges_reset[key] + '"></i>';
    //                         }
    //                         string += '</li>';
    //                         main_string = price_string + string;
    //                         $('.modern-filter-badges ul.stm-filter-chosen-units-list').html(main_string);
    //                     }
    //                 }
    //             }
    //             if ($.isEmptyObject(badges)) {
    //                 main_string = price_string + string;
    //                 $('.modern-filter-badges ul.stm-filter-chosen-units-list').html(main_string);
    //             };
    //             var badges_length = Object.keys(badges).length;
    //             if (badges_length > 0) {
    //                 var badgesWidth = 0;
    //                 var badgesMargin = 15;
    //                 var badgesRowWidth = $('.stm-filter-chosen-units-list').outerWidth();
    //                 $('.stm-filter-chosen-units-list li').each(function() {
    //                     badgesWidth += $(this).outerWidth();
    //                 });
    //                 badgesWidth += badgesMargin * (badges_length - 1);
    //                 var row_number = (badgesWidth / badgesRowWidth) + 1;
    //                 $('.stm-filter-chosen-units-list').css({
    //                     height: (parseInt(row_number) * 47) + 'px'
    //                 });
    //             } else {
    //                 $('.stm-filter-chosen-units-list').css({
    //                     height: 0
    //                 });
    //             }
    //             console.log(stmIsotope);
    //             if (typeof stmIsotope != 'undefined') {
    //                 var stmIsoData = stmIsotope.data('isotope');
    //                 if (typeof stmIsoData != 'undefined') {
    //                     if (typeof stmIsoData.filteredItems != 'undefined') {
    //                         $('.stm-modern-filter-found-cars span').text(stmIsoData.filteredItems.length);
    //                     }
    //                 }
    //             }
    //         })
    //         // $(".stm-price-range").on('slide', function(event, ui) {
    //         //     var minPrice = ui.values[0];
    //         //     var maxPrice = ui.values[1];
    //         //     stmIso.isotope({
    //         //         filter: function() {
    //         //             var matches = [];
    //         //             var itemPrice = $(this).data('price');
    //         //             if (Object.keys(stmFilterGroups).length > 0) {
    //         //                 for (var key in stmFilterGroups) {
    //         //                     if (stmFilterGroups.hasOwnProperty(key)) {
    //         //                         for (var k = 0; k < stmFilterGroups[key].length; k++) {
    //         //                             var match = false;
    //         //                             if ($(this).hasClass(stmFilterGroups[key][k])) {
    //         //                                 matches[key] = true;
    //         //                             }
    //         //                         }
    //         //                     }
    //         //                 }
    //         //                 var final_match = false;
    //         //                 if (Object.keys(matches).length == Object.keys(stmFilterGroups).length) {
    //         //                     if (Object.keys(matches).length > 0) {
    //         //                         for (var m_key in matches) {
    //         //                             if (matches.hasOwnProperty(m_key)) {
    //         //                                 if (matches[m_key]) {
    //         //                                     final_match = true;
    //         //                                 } else {
    //         //                                     final_match = false;
    //         //                                 }
    //         //                             }
    //         //                         }
    //         //                     } else {
    //         //                         final_match = false;
    //         //                     }
    //         //                 }
    //         //                 if (final_match) {
    //         //                     if (typeof minPrice != 'undefined' && typeof maxPrice != 'undefined' && typeof itemPrice != 'undefined') {
    //         //                         return parseInt(itemPrice, 10) >= minPrice && parseInt(itemPrice, 10) <= maxPrice;
    //         //                     } else {
    //         //                         return parseInt(itemPrice, 10) >= minPrice && parseInt(itemPrice, 10) <= maxPrice;
    //         //                     }
    //         //                 }
    //         //             } else {
    //         //                 return parseInt(itemPrice, 10) >= minPrice && parseInt(itemPrice, 10) <= maxPrice;
    //         //             }
    //         //         }
    //         //     })
    //         //     if (typeof stmIsotope != 'undefined') {
    //         //         var stmIsoData = stmIsotope.data('isotope');
    //         //         if (typeof stmIsoData != 'undefined') {
    //         //             if (typeof stmIsoData.filteredItems != 'undefined') {
    //         //                 $('.stm-modern-filter-found-cars span').text(stmIsoData.filteredItems.length);
    //         //             }
    //         //         }
    //         //     }
    //         //     price_string = '<li><span>Price:</span> ' + minPrice + ' - ' + maxPrice;
    //         //     price_string += '<i class="fa fa-close stm-clear-listing-one-unit" data-select="price"></i></li>';
    //         //     main_string = price_string + string;
    //         //     $('.modern-filter-badges ul.stm-filter-chosen-units-list').html(main_string);
    //         //     $('.stm-filter-chosen-units-list').height('47');
    //         // });
    //     }
    // }

    // function stm_filter_links() {
    //     $('body').on('click', '.stm-single-filter-link', function() {
    //         stm_single_filter_link = true;
    //         var stm_name = $(this).data('slug');
    //         var stm_value = $(this).data('value');
    //         if (typeof stm_name !== 'undefined' && typeof stm_value !== 'undefined') {
    //             $('.reset-all').trigger('click');
    //             $('#stm-filter-links-input').attr('name', stm_name);
    //             $('#stm-filter-links-input').val(stm_value);
    //         }
    //     })
    // }
})(jQuery);

// function loadMoreCars(that, category, taxonomy, offset, per_page) {
//     var $ = jQuery;
//     $.ajax({
//         url: ajaxurl,
//         data: {
//             action: 'stm_ajax_load_more_cars',
//             category: category,
//             taxonomy: taxonomy,
//             offset: offset,
//             per_page: per_page
//         },
//         method: 'POST',
//         dataType: 'json',
//         beforeSend: function() {
//             $(that).addClass('not-visible');
//             $(that).closest('.dp-in').find('.preloader').fadeIn(600);
//         },
//         success: function(data) {
//             $(that).closest('.dp-in').find('.preloader').hide();
//             if (data['content'] && data['appendTo']) {
//                 $(data['appendTo'] + ' .car-listing-row').append(data['content']);
//             }
//             if (data['button']) {
//                 $(that).attr('onclick', data['button']).removeClass('not-visible');
//             } else {
//                 $(data['appendTo']).find('.car-listing-actions').addClass('all-done');
//                 that.parent().text('');
//             }
//         }
//     });
// };
! function(a, b) {
    "use strict";

    function c() {
        if (!e) {
            e = !0;
            var a, c, d, f, g = -1 !== navigator.appVersion.indexOf("MSIE 10"),
                h = !!navigator.userAgent.match(/Trident.*rv:11\./),
                i = b.querySelectorAll("iframe.wp-embedded-content");
            for (c = 0; c < i.length; c++)
                if (d = i[c], !d.getAttribute("data-secret")) {
                    if (f = Math.random().toString(36).substr(2, 10), d.src += "#?secret=" + f, d.setAttribute("data-secret", f), g || h) a = d.cloneNode(!0), a.removeAttribute("security"), d.parentNode.replaceChild(a, d)
                } else;
        }
    }
    var d = !1,
        e = !1;
    if (b.querySelector)
        if (a.addEventListener) d = !0;
    if (a.wp = a.wp || {}, !a.wp.receiveEmbedMessage)
        if (a.wp.receiveEmbedMessage = function(c) {
                var d = c.data;
                if (d.secret || d.message || d.value)
                    if (!/[^a-zA-Z0-9]/.test(d.secret)) {
                        var e, f, g, h, i, j = b.querySelectorAll('iframe[data-secret="' + d.secret + '"]'),
                            k = b.querySelectorAll('blockquote[data-secret="' + d.secret + '"]');
                        for (e = 0; e < k.length; e++) k[e].style.display = "none";
                        for (e = 0; e < j.length; e++)
                            if (f = j[e], c.source === f.contentWindow) {
                                if (f.removeAttribute("style"), "height" === d.message) {
                                    if (g = parseInt(d.value, 10), g > 1e3) g = 1e3;
                                    else if (200 > ~~g) g = 200;
                                    f.height = g
                                }
                                if ("link" === d.message)
                                    if (h = b.createElement("a"), i = b.createElement("a"), h.href = f.getAttribute("src"), i.href = d.value, i.host === h.host)
                                        if (b.activeElement === f) a.top.location.href = d.value
                            } else;
                    }
            }, d) a.addEventListener("message", a.wp.receiveEmbedMessage, !1), b.addEventListener("DOMContentLoaded", c, !1), a.addEventListener("load", c, !1)
}(window, document);

//Ajax Pagination
// $(window).on('hashchange', function() {
//     if (window.location.hash) {
//         console.log("hash change");
//         var page = window.location.hash.replace('#', '');
//         if (page == Number.NaN || page <= 0) {
//             return false;
//         } else {
//             console.log('hashchange');
//             buildUrl(page);
//         }
//     }
// });
$(document).ready(function () {
    var vehicle_price;
    var interest_rate;
    var down_payment;
    var period_month;

    var stmCurrency = "R ";
    var stmPriceDel = " ";
    var stmCurrencyPos = "left";

    $('.calculate_loan_payment').click(function (e) {
        e.preventDefault();

        //Useful vars
        var current_calculator = $(this).closest('.stm_auto_loan_calculator');

        var calculator_alert = current_calculator.find('.calculator-alert');
        //First of all hide alert
        calculator_alert.removeClass('visible-alert');

        //4 values for calculating
        vehicle_price = parseFloat(current_calculator.find('input.vehicle_price').val());

        interest_rate = parseFloat(current_calculator.find('input.interest_rate').val());
        interest_rate = interest_rate / 1200;

        down_payment = parseFloat(current_calculator.find('input.down_payment').val());

        period_month = parseFloat(current_calculator.find('input.period_month').val());

        //Help vars

        var validation_errors = true;

        var monthly_payment = 0;
        var total_interest_payment = 0;
        var total_amount_to_pay = 0;

        if (isNaN(vehicle_price)) {
            calculator_alert.text("Please fill Vehicle Price field");
            calculator_alert.addClass('visible-alert');
            current_calculator.find('input.vehicle_price').closest('.form-group').addClass('has-error');
            validation_errors = true;
        } else if (isNaN(interest_rate)) {
            calculator_alert.text("Please fill Interest Rate field");
            calculator_alert.addClass('visible-alert');
            current_calculator.find('input.interest_rate').closest('.form-group').addClass('has-error');
            validation_errors = true;
        } else if (isNaN(period_month)) {
            calculator_alert.text("Please fill Period field");
            calculator_alert.addClass('visible-alert');
            current_calculator.find('input.period_month').closest('.form-group').addClass('has-error');
            validation_errors = true;
        } else if (isNaN(down_payment)) {
            calculator_alert.text("Please fill Down Payment field");
            calculator_alert.addClass('visible-alert');
            current_calculator.find('input.down_payment').closest('.form-group').addClass('has-error');
            validation_errors = true;
        } else if (down_payment > vehicle_price) {
            //Check if down payment is not bigger than vehicle price
            calculator_alert.text("Down payment can not be more than vehicle price");
            calculator_alert.addClass('visible-alert');
            current_calculator.find('input.down_payment').closest('.form-group').addClass('has-error');
            validation_errors = true;
        } else {
            validation_errors = false;
        }

        if (!validation_errors) {
            var interest_rate_unused = interest_rate;

            if(interest_rate == 0) {
                interest_rate_unused = 1;
            }
            monthly_payment = (vehicle_price - down_payment) * interest_rate_unused * Math.pow(1 + interest_rate, period_month);
            var monthly_payment_div = ((Math.pow(1 + interest_rate, period_month)) - 1);
            if(monthly_payment_div == 0) {
                monthly_payment_div = 1;
            }

            monthly_payment = monthly_payment/monthly_payment_div;
            monthly_payment = monthly_payment.toFixed(2);

            total_amount_to_pay = down_payment + (monthly_payment*period_month);
            total_amount_to_pay = total_amount_to_pay.toFixed(2);

            total_interest_payment = total_amount_to_pay - vehicle_price;
            total_interest_payment = total_interest_payment.toFixed(2);

            current_calculator.find('.stm_calculator_results').slideDown();
            current_calculator.find('.monthly_payment').text(stmCurrency+updateCurrency(monthly_payment));
            current_calculator.find('.total_interest_payment').text(stmCurrency+updateCurrency(total_interest_payment));
            current_calculator.find('.total_amount_to_pay').text(stmCurrency+updateCurrency(total_amount_to_pay));
        }
    })

    function updateCurrency (c, d, t){
            var value = parseFloat(c);
            return value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    };

    $(".numbersOnly").on("keypress keyup blur", function (event) {
        if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
            event.preventDefault();
        }
        if ($(this).val() != '') {
            $(this).closest('.form-group').removeClass('has-error');
        }
    });
});
$(document).ready(function() {
    $(document).on('click', '.page-numbers a', function (e) {
        var url = $(this).attr('href');
        var page_no = url.split('page=')[1];

        $('[name="page"]').val(page_no);
        buildUrl(true);

        e.preventDefault();
    });
});
function refreshSelect($input, data, selected,type) {
    $input.html($('<option value="">'+type+'</option>'));
    for (var key in data) {
        var $option = $('<option />')
            .prop('value', data[key]['id'])
            .text(data[key]['text'])
        ;
        if(data[key]['id'] == selected) {
            $option.prop('selected',true);
        }
        $input.append($option);
    }
}
function buildUrl(add_page) {
    if(!add_page) {
        var url_string = $("#search_filter :input[value!='']").not(".pager").serialize();
    }else {
        var url_string = $("#search_filter :input[value!='']").serialize();
    }

    var push_url = $('#search_filter').data('push_url');

    $.ajax({
        cache: false,
        url : '?'+url_string,
        dataType: 'json',
        context: this,
        beforeSend: function(){
            $('.stm-ajax-row').addClass('stm-loading');
            $('#search_filter .select2-container--default .select2-selection--single .select2-selection__arrow b').addClass('stm-preloader');
        }
    }).done(function (data) {
        $('.stm-ajax-row').removeClass('stm-loading');
        $('#search_filter .select2-container--default .select2-selection--single .select2-selection__arrow b').removeClass('stm-preloader');
        $('#search_filter select').prop("disabled", false);
        $('#search_filter select').select2("destroy");
        $('#search_filter select').select2();

        if(push_url)
        window.history.pushState({state: new Date().getTime()}, "", '?'+url_string);

        // if(data.select_data.length > 0) {

        if(typeof data.select_data.make != 'undefined' && data.select_data.make.length > 0) {
            var make_input = $('#make');
            var data_makes = data.select_data.make;
            refreshSelect(make_input, data_makes, make_input.val(),'- Make');
        }

        if(typeof data.select_data.model != 'undefined' && data.select_data.model.length > 0) {
            var model_input = $('#model');
            var data_models = data.select_data.model;
            refreshSelect(model_input, data_models, model_input.val(),'- Model');
        }

        if(typeof data.select_data.year != 'undefined' && data.select_data.year.length > 0) {
            var year_input = $('#year');
            var data_years = data.select_data.year;
            refreshSelect(year_input, data_years, year_input.val(),'- Year');
        }

        if(typeof data.select_data.body_type != 'undefined' && data.select_data.body_type.length > 0) {
            var body_types = $('#body');
            var data_body = data.select_data.body_type;
            refreshSelect(body_types, data_body, body_types.val(),'- Body');
        }

        if(typeof data.select_data.dealer_code != 'undefined' && data.select_data.dealer_code.length > 0) {
            var dealer_codes = $('#dealer_code');
            var dealer_code = data.select_data.dealer_code;
            refreshSelect(dealer_codes, dealer_code, dealer_codes.val(),'- Dealerships');
        }

        if(typeof data.select_data.dealer_name != 'undefined' && data.select_data.dealer_name.length > 0) {
            var dealer_names = $('#d_name');
            var dealer_name = data.select_data.dealer_name;
            refreshSelect(dealer_names, dealer_name, dealer_names.val(),'- Dealerships');
        }

        //$('.mycontent').html(data.html).trigger('contentchanged');
        $('.mycontent').html(data.html);



    }).fail(function () {
        alert('Content could not be loaded.');
    });
}